<template>
  <div>
    <a @click="editAuthor" v-if="canEdit">
      <va-icon
        name="fa fa-pencil-square-o"
        :size="21"
        class="action-icon pointer"
      />
    </a>
    <va-modal
      v-model="showModal"
      title="Update Author"
      :okText="$t('modal.confirm')"
      :cancelText="$t('modal.cancel')"
      @ok="updateAuthorFromLocal"
      @cancel="resetForm"
    >
      <div class="author-pic">
        <img :src="getPeoplePic" @click="$refs[`${author.id}_thumb`].click()" />
        <input
          :ref="`${author.id}_thumb`"
          type="file"
          style="display: none;"
          v-on:change="uploadThumbnail"
        />
      </div>
      <div>
        <va-input
          v-model="newAuthor.name"
          label="Author Name"
          placeholder="Author name"
        />
        <va-input
          v-model="newAuthor.twitter"
          label="Twitter"
          placeholder="Author twitter handle"
        />
        <va-input
          v-model="newAuthor.instagram"
          label="Instagram"
          placeholder="Author instagram handle"
        />
        <va-input
          v-model="newAuthor.patreon"
          label="Patreon"
          placeholder="Author patreon page"
        />
        <va-input
          v-model="newAuthor.website"
          label="Website"
          placeholder="Author website"
        />
      </div>
    </va-modal>

    <a @click="deleteAuthorShowModal = true" v-if="canDelete">
      <va-icon name="fa fa-trash-o" :size="21" class="action-icon pointer" />
    </a>
    <va-modal
      v-model="deleteAuthorShowModal"
      size="small"
      title="Delete Genre"
      :okText="$t('modal.confirm')"
      :cancelText="$t('modal.cancel')"
      @ok="deleteAuthorReq(false)"
      @cancel="resetForm"
    >
      Are you sure you want to delete author/artist?
    </va-modal>

    <a @click="forceDeleteAuthorShowModal = true" v-if="canDelete">
      <va-icon
        name="fa fa-trash-o"
        :size="21"
        class="action-icon pointer force-action-item"
      />
    </a>
    <va-modal
      v-model="forceDeleteAuthorShowModal"
      size="small"
      title="Delete Genre"
      :okText="$t('modal.confirm')"
      :cancelText="$t('modal.cancel')"
      @ok="deleteAuthorReq(true)"
      @cancel="resetForm"
    >
      Are you sure you want to <b>FORCE</b> delete author/artist, it will delete
      author from everywhere
    </va-modal>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { NEW_AUTHOR } from '../../constants/defaultValues';
import {
  updateAuthor,
  deleteAuthor,
  forceDeleteAuthor,
} from '../../apollo/api/authors';
import { authorPicFilter } from '../../mixins/filters';

export default {
  name: 'author-actions',
  props: { author: Object, canEdit: Boolean, canDelete: Boolean },
  data() {
    return {
      newAuthor: NEW_AUTHOR,
      showModal: false,
      deleteAuthorShowModal: false,
      forceDeleteAuthorShowModal: false,
    };
  },
  computed: {
    getPeoplePic() {
      if (
        typeof this.newAuthor.picture === 'object' &&
        this.newAuthor.picture !== null
      ) {
        return URL.createObjectURL(this.newAuthor.picture);
      }
      return authorPicFilter(this.author.picture);
    },
  },
  methods: {
    ...mapMutations(['setBackgroundLoading']),
    resetForm() {
      this.newAuthor = { ...this.author, picture: null };
    },
    editAuthor() {
      this.resetForm();
      this.showModal = true;
    },
    async uploadThumbnail({ target: { files = [] } }) {
      if (!files.length) {
        return;
      }
      this.newAuthor.picture = files[0];
    },
    async updateAuthorFromLocal() {
      this.setBackgroundLoading(true);
      try {
        const { updatePeople } = await updateAuthor(
          this.author.id,
          this.newAuthor,
        );

        this.$emit('updateAuthor', updatePeople.people);
        this.showToast('Author updated successfully', {
          position: 'top-right',
          duration: 800,
          fullWidth: false,
        });
        this.resetForm();
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.setBackgroundLoading(false);
    },
    async deleteAuthorReq(forceDeleteFlag = false) {
      this.setBackgroundLoading(true);
      try {
        if (forceDeleteFlag) {
          await forceDeleteAuthor(this.author.id);
        } else {
          await deleteAuthor(this.author.id);
        }

        this.showToast('Author deleted successfully', {
          position: 'top-right',
          duration: 800,
          fullWidth: false,
        });
        this.resetForm();
        window.location.reload();
      } catch (e) {
        console.log(e);
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.setBackgroundLoading(false);
    },
  },
};
</script>

<style lang="scss">
.author-pic {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  border: 3px solid crimson;
  margin: auto;
  align-items: center;

  img {
    width: 98%;
    height: 98%;
    border-radius: 50%;
  }
}
</style>
